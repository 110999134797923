export default defineNuxtPlugin((nuxtApp) => {
  function findUID(feed, uid, value, operation) {
    /*
  Alright, this is the guts of our ui, we search by uid and depending on the operation we do a related action.
  If a user is updating a field, we change the value in the fields
  If a user is inserting a element, we insert a new element in elements
  If a user is removing a element, we remove the element
  We're recursively searching through a tree that looks like this:
  children[]
    child
    | fields {}
      field
      | elements[] <--- recursive function is called here
      fields{}
        field(type=elements)
        | elements[]
        ...and so on
  */
    // was feed.entries() but threw error so we swapped with  Object.entries(feed)
    for (const [index, item] of Object.entries(feed)) {
      if (item.uid === uid) {
        if (operation === "remove") {
          feed.splice(index, 1);
          return;
        }
        if (operation === "title") {
          feed[index].title = value; // https://stackoverflow.com/a/68575342/6866307
          return;
        }
        if (operation === "fetch") {
          return feed[index];
        }
        if (operation === "content-placeholder") {
          for (const key in feed[index].fields) {
            if (feed[index].fields[key].uid === value) {
              // console.log("foundit", feed[index].fields[key].uid);
              return feed[index].fields[key].elements.push({
                component: "ContentPlaceholder",
                name: "Page Content",
                uid: "page-content-placeholder",
              });
            }
          }
        }
      } else {
        for (const key in item) {
          if (key === "fields") {
            for (const prop in item[key]) {
              if (item[key][prop].uid === uid) {
                if (operation === "update") {
                  item[key][prop].value = value;
                  // console.log("UPDATE", feed, uid, operation, item[key], value);
                  return;
                }
                if (operation === "insert") {
                  // console.log("INSERT", feed, uid, operation, item[key], value);
                  item[key][prop].elements.push(value);
                  return;
                }
                if (operation === "upsert") {
                  // console.log(
                  //   "UPSERT",
                  //   // feed,
                  //   uid,
                  //   // operation,
                  //   // item[key],
                  //   value,
                  //   item[key][prop].elements
                  // );
                  item[key][prop].elements = value;
                  return;
                }
                if (operation === "title") {
                  feed[index].title = value;
                  return;
                }
              } else {
                // console.log("weee deeeep!");
                for (const prop2 in item[key][prop]) {
                  if (prop2 === "elements") {
                    const child = findUID(
                      item[key][prop][prop2],
                      uid,
                      value,
                      operation
                    );
                    if (child) return child;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return {
    provide: {
      findUID,
    },
  };
});

// alternate version by bard:
// for (const [index, item] of Object.entries(feed)) {
//   if (item.uid === uid) {
//     switch (operation) {
//       case "remove":
//         feed.splice(index, 1);
//         return;
//       case "title":
//         feed[index].title = value;
//         return;
//       case "fetch":
//         return feed[index];
//       case "update":
//         item.value = value;
//         return;
//       case "insert":
//         item.elements.push(value);
//         return;
//       case "upsert":
//         item.elements = value;
//         return;
//     }
//   } else if (item.fields) {
//     for (const prop in item.fields) {
//       if (item.fields[prop].uid === uid) {
//         switch (operation) {
//           case "update":
//             item.fields[prop].value = value;
//             return;
//           case "insert":
//             item.fields[prop].elements.push(value);
//             return;
//           case "upsert":
//             item.fields[prop].elements = value;
//             return;
//         }
//       } else if (item.fields[prop].elements) {
//         const child = findUID(item.fields[prop].elements, uid, value, operation);
//         if (child) return child;
//       }
//     }
//   }
// }
